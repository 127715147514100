import { ArrowRight, MousePointerClick } from 'lucide-react'
import ChatbotRequestModal from '.././modals/chatbot-request'
import { ModalTrigger } from '.././ui/animated-modal'
import { ConfettiButton } from '.././magicui/confetti'
import { cn } from '@/lib/utils'
import ActionSelectorModal from '../modals/action-selector'
import TextShimmer from '../ui/text-shimmer'
import { Button } from '../ui/button'

export default function PricingCTA({
  children,
  variant = 'default',
  className
}: {
  children: React.ReactNode
  variant?: any
  className?: string
}) {
  return (
    <ActionSelectorModal>
      <ModalTrigger className="group">
        <ConfettiButton className="transition-all bg-transparent hover:bg-transparent">
          <Button
            variant={variant}
            className={cn(className, 'flex items-center gap-2')}>
            {children}
          </Button>
        </ConfettiButton>
      </ModalTrigger>
    </ActionSelectorModal>
  )
}
